import Typography from "@mui/material/Typography";
import { useRecoilValue } from "recoil";
import { currentRewardPointsState } from "../../atoms/cartAtoms";
import { numberWithCommas } from "../../utils";
import { checkRewardEligibility } from "../../utils/jwt";
import Loader from "../Loader";

function RewardsOnOrder(props) {
  const { rewardPoints, isFetching, shouldConsider = false } = props;
  const points = useRecoilValue(currentRewardPointsState);
  const currentPoints = shouldConsider ? rewardPoints : points;
  const eligibleForRewards = checkRewardEligibility();

  if (!eligibleForRewards) {
    return <></>;
  }
  if (isFetching) {
    return <Loader style={{ minHeight: "10vh" }} />;
  }

  return (
    <Typography variant="h5" mt={2}>
      {currentPoints > 0 &&
        `You can earn ${numberWithCommas(currentPoints)} points on this order`}
    </Typography>
  );
}

export default RewardsOnOrder;
