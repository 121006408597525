import { memo, lazy } from "react";
import { styled } from "@mui/material/styles";
const FreeShippingBanner = lazy(() =>
  import("../components/FreeShippingBanner")
);

const ResponsiveBannerImage = ({
  banner = {},
  className,
  imageStyle = {},
  showFreeShipping = true,
}) => {
  const { pageType, desktop, desktop2x, portrait, portrait2x } = banner;
  const imageScr = desktop?.[0].fields?.file?.url || "";

  let srcSetPStr = [];
  let srcSetStr = [];

  if (desktop && desktop.length === 1) {
    srcSetStr.push(desktop?.[0].fields?.file?.url + " 1x");
  }

  if (desktop2x && desktop2x.length === 1) {
    srcSetStr.push(desktop2x?.[0].fields?.file?.url + " 2x");
  }

  if (portrait && portrait.length === 1) {
    srcSetPStr.push(portrait?.[0].fields?.file?.url + " 1x");
  }

  if (portrait2x && portrait2x.length === 1) {
    srcSetPStr.push(portrait2x?.[0].fields?.file?.url + " 2x");
  }

  if (srcSetStr.length > 0 && srcSetPStr.length > 0) {
    return (
      <picture style={{ width: "100%" }}>
        <source
          srcSet={srcSetPStr.join(",")}
          media="(max-width: 768px) and (orientation: portrait)"
        />
        <source srcSet={srcSetStr.join(",")} />
        <StyledBannerImage
          src={imageScr}
          alt={pageType}
          className={className}
          style={imageStyle}
        />
        {showFreeShipping && <FreeShippingBanner />}
      </picture>
    );
  } else if (srcSetStr.length === 2) {
    return (
      <>
        <StyledBannerImage
          src={imageScr}
          srcSet={srcSetStr.join(",")}
          alt={pageType}
          className={className}
        />
        {showFreeShipping && <FreeShippingBanner />}
      </>
    );
  }
  return (
    <>
      <StyledBannerImage src={imageScr} alt={pageType} className={className} />
      {showFreeShipping && <FreeShippingBanner />}
    </>
  );
};

export default memo(ResponsiveBannerImage);

export const StyledBannerImage = styled("img")(() => ({
  width: "100%",
  minHeight: "180px",
}));
