import ReactGA from "react-ga4";
import { roundTo } from "./roundTo";

export const setAccountId_GA = (data) => {
  if (data?.accountId) {
    ReactGA.set({ accountId: data.accountId });
  }
};

// eCommerce events

export const viewItem_GA = (product) => {

  ReactGA.gtag("event", "view_item", {
    currency: "USD",
    items: [
      {
        id: product.id,
        item_name: product.item_name,
      },
    ],
  });
}



export const addToCart_GA = (product) => {
  ReactGA.gtag("event", "add_to_cart", {
    currency: "USD",
    items: [
      {
        id: product.id,
        quantity: product.quantity,
      },
    ],
  });
}

export const viewCart_GA = (order) => {
  ReactGA.gtag("event", "view_cart", {
    currency: "USD",
    // totalAmountWithoutTax excludes tax and shipping
    // It includes the discount from coupons.
    // It does not include the adjustment from reward points.
    value: order?.totalAmountWithoutTax || 0,
    items: getItems(order),
  })
}

export const beginCheckout_GA = (order) => {
  if (order) {
    ReactGA.gtag("event", "begin_checkout", {
      currency: "USD",
      // totalAmountWithoutTax excludes tax and shipping
      // It includes the discount from coupons.
      // It does not include the adjustment from reward points.
      value: order.totalAmountWithoutTax || 0,
      items: getItems(order),
    })
  }
}

export const purchase_GA = (order) => {
  if (!order) return;

  const {
    orderTotal: {
      rewardsPointsDiscount = 0,
      subtotal = 0,
      shipping = 0,
      taxes = 0
    } = {},
    totalDiscount = 0,
    couponCode = ''
  } = order;

  const transactionId = order.orderTotal?.chargeId;

  const productTotal = subtotal >= (rewardsPointsDiscount + totalDiscount)
    ? subtotal - (rewardsPointsDiscount + totalDiscount)
    : 0;

  ReactGA.gtag("event", "purchase", {
    currency: "USD",
    transaction_id: transactionId,
    value: roundTo(productTotal, 2),
    coupon: couponCode,
    shipping,
    tax: taxes,
    items: getItems(order),
  });
};


const getItems = (order) => {
  if (!order?.items) {
    return [];
  }

  const items = [];
  let idx = 0;
  order.items.forEach((item) => {
    const hasCoupon = item.discountType === "coupon"
    const coupon = item.hasDiscount && hasCoupon ? item.discountId : "";
    items.push({
      id: item.sku,
      item_name: item?.shortName || "",
      price: item?.discountedAmount || 0,
      quantity: item?.quantity || 0,
      discount: item?.discountAmount || 0,
      item_variant: item.skuType,
      coupon: coupon,
      index: idx,
    });
    idx += 1;
  });
  return items;
}

// Calendar events

// User views calendar of courses / events

// * view_regen_calendar_page - User visits the calendar page
// * Map using existing event: page_view
// * Event parameters:
//   * path: "/calendar"

// User views details of a course / event
export const viewRegenEvent_GA = (event) => {
  ReactGA.gtag("event", "view_regen_event", {
    course_id: event.course_id,
    title: event.title,
    event_start_date: event.event_start_date,
    event_city: event.event_city,
    event_state: event.event_state,
  });
}

// Guest user clicks on "Sign Up as Guest" button
export const beginRegisterRegenEventAsGuest_GA = (event_id) => {
  ReactGA.gtag("event", "begin_register_regen_event_as_guest", {
    event_id: event_id,
  });
}

// Signed in user registers for a course/event
export const registerRegenEvent_GA = (event_id) => {
  ReactGA.gtag("event", "register_regen_event", {
    event_id: event_id,
  });
}
// Guest user registers for a course/event
export const registerRegenEventAsGuest_GA = (event_id) => {
  ReactGA.gtag("event", "register_regen_event_as_guest", {
    event_id: event_id,
  });
}

// GAAIN events

// User views education page

// * view_regen_education_page - User visits the education page
// * Map using existing event: page_view
// * Event parameters:
//   * path: "/education"

// User visits GAAIN site
export const viewGAAINSite_GA = () => {
  ReactGA.gtag("event", "view_regen_gaain_site", {});
}

// User Click Maries Gift Button on Charity Page
export const submitNameForMariesGift = () => {
  ReactGA.gtag("event", "submit_name_for_maries_gift", {});
}

