export const CONTENT_PAGE_SIZE = 12; //This is number of items displayed on content pages

export const CALENDAR_TABS = [
  {
    itemKey: "hae",
    title: "HAE Events",
  },
  {
    itemKey: "multiday",
    title: "Four Day Events",
  },
  {
    itemKey: "inperson",
    title: "In-Person Classes",
  },
  {
    itemKey: "tradeshow",
    title: "Trade Shows",
  },
  {
    itemKey: "virtual",
    title: "Virtual Classes",
  },
];

export const EVENT_MODE_ONLINE = "Online";

export const PRODUCT_DISCOUNT = "Product Discount";
export const FREE_SHIPPING = "Free Shipping";
export const DEAL_STATUS = {
  UPCOMING: "Upcoming",
  ACTIVE: "Active",
  ENDING: "Ending",
  ENDED: "Ended",
};

export const ADMIN_WITH_PLACE_ORDER_PERMISSION = [
  "jmcdaniel@glymedplus.com",
  "jhall@glymedplus.com",
];
export const ASSETS_BASE_URL = process.env.REACT_APP_REGEN_CONTENT;

let startDate = new Date(Date.UTC(2023,5,13,7,0,0));
let endDate = new Date(Date.UTC(2023,5,21,7,0,0));
let today = new Date();

export const  RETURN_POLICY = (today >= startDate && today < endDate) ? 'glymed_plus_returns_policies_rebrand.html' : 'glymed_plus_returns_policies.html';
