import Grid from "@mui/material/Grid";
import CartThumbnail from "./CartThumbnail";

function CartList({ cartData }) {
  return (
    <Grid item sx={{ alignSelf: "flex-start", width: "100%" }}>
      {cartData.map((item, index) => {
        return <CartThumbnail data={item} key={index} />;
      })}
    </Grid>
  );
}

export default CartList;
