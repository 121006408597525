import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { SIGNIN } from "./Constants";

function CartActionButton({
  buttonText,
  isSuccess,
  isError,
  alertText,
  enabled = true,
  tooltipText = "",
  btnStyle = {},
  actionButtonClicked = () => {},
}) {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (isSuccess || isError) {
      setShowAlert(true);
    }
  }, [isError, isSuccess]);

  const handleAddToCartClick = () => {
    actionButtonClicked();
  };

  const handleClose = () => {
    setShowAlert(false);
  };
  return (
    <>
      <Button
        variant={
          buttonText === SIGNIN ? "btn-prominent-fluid" : "btn-primary-fluid"
        }
        sx={{
          mt: "1.4rem",
          ...btnStyle,
        }}
        onClick={handleAddToCartClick}
        disabled={!enabled}
      >
        {buttonText}
      </Button>
      <Snackbar open={showAlert} autoHideDuration={4000} onClose={handleClose}>
        <Alert severity="success">{alertText}</Alert>
      </Snackbar>
    </>
  );
}

CartActionButton.propTypes = {
  buttonText: PropTypes.string,
  alertText: PropTypes.string,
  enabled: PropTypes.bool,
  tooltipText: PropTypes.string,
};

export default CartActionButton;
